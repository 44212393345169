import { useEffect, useState } from "react";
import { collectionData } from "rxfire/firestore";
import { combineLatest } from "rxjs";

import { WithId } from "../types/typeHelpers";

interface UseCollectionSubscriberParams {
  queries: firebase.firestore.Query[];
  idField?: string;
  deps?: Array<any>;
}

function useCollectionSubscriber2<T>({
  queries,
  idField = "id",
  deps = [],
}: UseCollectionSubscriberParams) {
  const [documents, setDocuments] = useState<WithId<T>[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getDocuments$ = combineLatest(
      queries.map((query) => collectionData<WithId<T>>(query, idField))
    );
    const subscription = getDocuments$.subscribe({
      next: (observedDocuments) => {
        setDocuments(observedDocuments.flat());
        setLoading(false);
      },
      error: (err: any) => {
        setError(err);
        setLoading(false);
      },
    });
    return () => subscription.unsubscribe();
  }, deps);

  return { documents, error, loading };
}

export default useCollectionSubscriber2;
